:root {
    --green1: rgb(22, 66, 60);
    --green2: rgb(106, 156, 137);
    --green3: rgb(196, 218, 210);
    --green4: rgb(233, 239, 236);
    --green5: rgb(246, 251, 122);
    --green6: rgb(180, 227, 128);
    --green7: rgb(13, 124, 102);
    --green8: rgb(65, 179, 162);
    --green9: rgb(189, 232, 202);
    --green10: rgb(224, 251, 226);

    --red1: rgb(153, 0, 0);
    --red2: rgb(204, 0, 0);
    --red3: rgb(255, 51, 51);
    --red4: rgb(255, 102, 102);
    --red5: rgb(255, 153, 153);
    --red6: rgb(255, 182, 193);
    --red7: rgb(128, 0, 0);
    --red8: rgb(255, 20, 20);
    --red9: rgb(255, 87, 51);
    --red10: rgb(255, 204, 204);
}
