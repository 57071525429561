@import './theme.css';


.item-container {
    display: flex;
    flex-direction: column;
    background-color: var(--green3);
    width: 400px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

.item-container.selected {
    background-color: var(--green1);
    color: white;
}

.item-container:hover {
    background-color: var(--green5);
}

.date-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green7);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 5px;
}

.resume-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
    gap: 50px;
}

.item-data {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.number-label {
    font-size: 18px;
    font-weight: bold;
}