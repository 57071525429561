@import "./theme.css";

.footer-container {
    display: flex;
    grid-area: footer;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: fit-content;
    background-color: var(--green1);
    color: white;
    padding: 20px 0px 20px 0px;
    gap: 30px;
}

.footer-sub-container {
    display: flex;
    gap: 50px;
}

.footer-download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-download-images-container {
    display: flex;
    gap: 10px;
}

.footer-contact-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-sub-container-title {
    font-weight: bold;
}

.footer-label {
    text-align: center;
}

.footer-img {
    width: 100px;
    height: 31px;
    border-radius: 6px;
}

.footer-icon-container {
    display: flex;
    align-self: center;
    gap: 10px;
}

.footer-icon {
    width: 40px;
    height: 40px;
    border-radius: 6px;
}