@import "./theme.css";

.welcome-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
    width: 100%;
    font-family: Arial, sans-serif;
    overflow: hidden;
}

.welcome-header {
    padding: 30px;
    background-color: var(--green1);
    color: white;
}

.welcome-footer {
    padding: 30px;
    background-color: var(--green1);
    color: white;
}

.welcome-content {
    flex: 1;
    text-align: center;
    background-color: var(--green3);
}
  
.welcome-title {
    font-size: 2.5em;
    color: #333;
}
  
.welcome-message {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
}
  
.welcome-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--green2);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.welcome-button:hover {
    background-color: #0056b3;
}

.welcome-img {
    width: 100px;
    height: 100px;
}


/* RESPONSIVE */
@media (max-width: 768px) {
    .welcome-header, .welcome-footer {
        padding: 20px;
        font-size: 1.2rem;
    }

    .welcome-title {
        font-size: 2em; /* Reduce el tamaño del título */
    }

    .welcome-message {
        font-size: 1em; /* Ajusta el tamaño del mensaje */
    }

    .welcome-button {
        font-size: 0.9em; /* Botón más pequeño */
        padding: 8px 16px; /* Ajusta el tamaño del botón */
    }
}

@media (max-width: 480px) {
    .welcome-header, 
    .welcome-footer {
        padding: 15px; /* Reduce aún más el espacio */
    }

    .welcome-title {
        font-size: 1em; /* Título más pequeño */
    }

    .welcome-message {
        font-size: 0.9em; /* Mensaje más pequeño */
    }

    .welcome-button {
        font-size: 0.8em; /* Botón más pequeño */
        padding: 6px 12px; /* Reduce el tamaño del botón */
    }
}