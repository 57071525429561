@import './theme.css';


/* LICENSE FORM */

.form-license-container {
    display: grid;
    grid-template-areas: 
        "section-1 section-2"
        "button button";
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    background-color: var(--green1);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.form-license-section-1-container {
    grid-area: section-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--green1);
    border-top-left-radius: 6px;
    gap: 10px;
}

.form-license-section-2-container {
    grid-area: section-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--green1);
    border-top-right-radius: 6px;
    gap: 10px;
}

.form-license-register-input {
    font-size: 18px;
    padding: 5px;
    border-radius: 6px;
    border-width: 0px;
}

.form-license-register-input.error {
    border-style: solid;
    border-width: 2px;
    border-color: red;
}

.form-license-error-label {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: var(--red4);
    background-color: var(--green4);
    border-radius: 4px;
}

.form-license-single-data-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 230px;
    gap: 5px;
}

.form-license-password-input-container {
    position: relative;
    display: flex;
    align-self: flex-start;
    max-width: 100%;
}

.form-license-password-icon {
    position: absolute;
    right: 10px;
    align-self: center;
}

.form-license-register-button-container {
    grid-area: button;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green1);
    padding: 30px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.form-license-register-button {
    align-self: center;
    font-size: 18px;
    width: 20%;
    padding: 10px;
    border-radius: 6px;
    border-width: 0px;
    background-color: white;
}


/* USER FORM */

.form-user-container {
    display: flex;
    flex-direction: column;
    background-color: var(--green1);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.form-user-first-section-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 10px;
    padding: 20px 20px 0px 20px;
    background-color: var(--green1);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    gap: 20px;
}

.form-user-section-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 20px 20px 0px 20px;
    background-color: var(--green1);
    gap: 20px;
}

.form-user-single-data-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 230px;
    gap: 5px;
}

.form-user-register-input {
    font-size: 18px;
    padding: 5px;
    border-radius: 6px;
    border-width: 0px;
}

.form-user-register-input.error {
    border-style: solid;
    border-width: 2px;
    border-color: red;
}

.form-user-error-label {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: var(--red4);
    background-color: var(--green4);
    border-radius: 4px;
}

.form-user-password-input-container {
    position: relative;
    display: flex;
    align-self: flex-start;
    max-width: 100%;
}

.form-user-password-icon {
    position: absolute;
    right: 10px;
    align-self: center;
}

.form-user-register-button-container {
    grid-area: button;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green1);
    padding: 30px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.form-user-register-button {
    align-self: center;
    font-size: 18px;
    width: 20%;
    padding: 10px;
    border-radius: 6px;
    border-width: 0px;
    background-color: white;
}


/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .form-license-container {
        display: flex;
        flex-direction: column;
        background-color: var(--green1);
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }

    .form-license-register-button {
        width: 80%;
    }

    .form-user-container {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }
    
    .form-user-first-section-container, .form-user-section-container {
        flex-direction: column;
    }

    .form-user-register-button {
        width: 80%;
    }
}