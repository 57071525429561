@import "./theme.css";

.current-day-button-new-day {
    align-self: center;
    background-color: var(--green1);
    color: white;
    padding: 20px;
    border-radius: 6px;
    font-size: large;
    font-weight: bold;
}

.current-day-content-1, .current-day-content-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--green4);
    gap: 10px;
}

.current-day-add-service-content, .current-day-edit-service-content {
    display: flex;
    flex-direction: column;
    background-color: var(--green5);
}

.current-day-main-container {
    display: flex;
    align-self: flex-start;
    justify-content: center;
}

.current-day-main-container.selected {
    display: flex;
    align-self: center;
    justify-content: center;
}