@import "./theme.css";

.profile-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.profile-header-container {
    display: flex;
    max-height: auto;
    width: 100%;
    background-color: var(--green1);
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.profile-header-title {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.profile-body-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px;
    background-color: var(--green4);
}

.profile-icon-container {
    align-self: center;
    font-size: 100px;
}

.profile-editable-container {
    display: flex;
    flex-direction: column;
}

.profile-user-data-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    padding: 30px;
    gap: 20px;
}

.profile-single-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.profile-input-edit {
    font-size: 18px;
    padding: 10px;
    background-color: var(--green3);
    border-radius: 6px;
    border-width: 0px;
}

.profile-data-label {
    font-size: x-large;
}

.profile-save-buttons-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 20px;
}

.profile-button-to-edit-profile {
    align-self: center;
    width: 20%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: var(--green2);
    border-width: 0px;
    border-radius: 6px;
    padding: 10px;
}

.profile-button-edit-profile {
    align-self: center;
    width: 60%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: var(--green2);
    border-width: 0px;
    border-radius: 6px;
    padding: 10px;
}

.profile-button-cancel-profile {
    align-self: center;
    width: 60%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: var(--red4);
    border-width: 0px;
    border-radius: 6px;
    padding: 10px;
}