@import "./theme.css";

.service-day-state-main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: var(--green1);
    border-radius: 6px;
}

.service-day-state-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.service-day-state-button {
    font-size: large;
    padding: 5px;
    border-radius: 5px;
    border-width: 0px;
    background-color: white;
}

.service-day-state-label {
    text-align: center;
}

/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .service-day-state-label {
        font-size: 12px;
    }
}