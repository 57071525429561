@import "./theme.css";

.configuration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: var(--green4);
}

.configuration-header-container {
    display: flex;
    max-height: auto;
    width: 100%;
    background-color: var(--green1);
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.configuration-header-title {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.configuration-main-container {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-color: var(--green1);
    border-radius: 6px;
    padding: 10px 30px 30px 30px;
    margin-top: 10px;
    width: 40%;
}

.configuration-title {
    border-top-style: dotted;
    border-top-width: 1px;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-color: var(--green1);
    padding: 10px;
}

.single-configuration-container {
    display: flex;
    align-items: center;
}

.configuration-input {
    height: 40px;
    width: 40px;
}

.configuration-label {
    text-align: left;
}

.configuration-button-delete-account {
    align-self: center;
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    border-width: 0px;
    border-radius: 6px;
    background-color: var(--red4);
    padding: 5px;
    margin-top: 60px;
}