@import "./theme.css";

.online-container {
    background-color: var(--green2);
    padding: 30px;
}

.online-my-state-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.online-icon-my-state {
    font-size: 24px;
}

.online-label-my-state {
    font-size: 24px;
    font-weight: bold;
}