@import "./theme.css";

.service-item-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: var(--green3);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 5px;
    padding-left: 30px;
    margin-bottom: 10px;
    gap: 10px;
}

.service-item-container.selected {
    background-color: var(--green1);
    color: white;
}

.service-item-container:hover {
    background-color: var(--green5);
    color: black;
}

.service-item-index-container {
    display: flex;
    align-self: center;
}

.service-item-index-label {
    font-size: 18px;
    font-weight: bold;
}

.service-item-data-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.service-item-price-label {
    font-size: 18px;
    font-weight: bold;
}

.service-item-tips-label {
    font-size: 16px;
    font-style: italic;
}

.service-item-card-container {
    display: flex;
    align-self: center;
    margin-left: auto;
    margin-right: 50px;
}

.service-item-card-label {
    font-size: 18px;
    font-weight: bold;
}