@import './theme.css';

*,
*::before,
*::after {
    box-sizing: border-box;
}

.login-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    background-color: var(--green2);
    color: white;
    gap: 40px;
}

.login-header {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    background-color: var(--green1);
    align-items: center;
    justify-content: center;
}

.login-main-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-height: fit-content;
    min-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.login-sub-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding: 30px 30px 30px 30px;
    background-color: var(--green1);
    border-radius: 20px;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.login-form-container {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    gap: 20px;
}

.login-input-container {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    overflow: hidden;
    gap: 5px;
}

.login-password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.login-remember-user-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.login-remember-user-checkbox {
    background-color: white;
    font-size: 20px;
    cursor: pointer;
}

.login-remember-user-label {
    cursor: pointer;
}

.login-input {
    position: relative;
    padding: 8px;
    font-size: 18px;
    border-width: 0px;
    border-radius: 6px;
}

.login-input.error {
    border-style: solid;
    border-width: 2px;
    border-color: red;
}

.login-form-error-label {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: block;
    color: white;
    border-radius: 4px;
}

.login-icon-password {
    position: absolute;
    right: 8px;
}

.login-button {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 60%;
    font-size: 20px;
    padding: 10px;
    border-radius: 6px;
    border-width: 0px;
    background-color: white;
}

.login-forgot-password-link {
    align-self: flex-end;
    font-size: 12px;
    color: var(--green5);
    cursor: pointer;
}

.login-to-register-link {
    color: var(--green5);
}


/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .login-sub-container {
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 80%;
        gap: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    }
    
}