@import "./theme.css";

.my-employees-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--green4);
}

.my-employees-license-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    background-color: var(--green2);
    border-radius: 6px;
    padding: 20px;
}

.my-employees-list-container {
    display: grid;
    grid-template-areas: "content content";
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    width: 80%;
    gap: 10px;
}

.my-employees-label-license-number {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.my-employees-label-license-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.my-employees-label-license-town {
    font-size: 18px;
    font-style: italic;
}

.my-employees-label-license-token {
    font-size: 16px;
    font-style: italic;
}

/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .my-employees-license-data-container {
        width: 80%;
    }
    .my-employees-label-license-number {
        font-size: 20px;
    }
    .my-employees-label-license-name {
        font-size: 16px;
    }
    .my-employees-label-license-town {
        font-size: 14px;
    }
    .my-employees-label-license-token {
        font-size: 12px;
    }
}