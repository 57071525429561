@import "./theme.css";

.change-password-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    background-color: var(--green4);
}

.change-password-header-container {
    display: flex;
    max-height: auto;
    width: 100%;
    background-color: var(--green1);
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.change-password-header-title {
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.change-password-body-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.change-password-form-container {
    display: flex;
    flex-direction: column;
    background-color: var(--green1);
    padding: 40px;
    border-radius: 8px;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.change-password-section-container {
    display: flex;
    flex-direction: column;
    color: white;
    max-width: 230px;
    gap: 5px;
}

.change-password-input-container {
    position: relative;
    display: flex;
    align-self: flex-start;
    max-width: 100%;
}

.change-password-input {
    font-size: 18px;
    padding: 5px;
    border-radius: 6px;
    border-width: 0px;
}

.change-password-input.error {
    border-style: solid;
    border-width: 2px;
    border-color: red;
}

.change-password-icon {
    position: absolute;
    right: 10px;
    align-self: center;
}

.change-password-label-error {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: var(--red4);
    background-color: var(--green4);
    border-radius: 4px;
}

.change-password-button {
    align-self: center;
    font-size: 18px;
    padding: 10px;
    border-radius: 6px;
    background-color: white;
    margin-top: 20px;
}

.change-password-button:hover {
    background-color: var(--green9);
}