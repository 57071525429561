@import "./theme.css";

/* HOME */

.home-container, .home-container-lateral-bar-hidden {
    display: grid;
    grid-template-areas: 
        "header header"
        "sidebar main-content"
        "footer footer";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: var(--sidebar-width) 1fr;
    min-height: 100vh;
    background-color: var(--green4);
    gap: 10px;
    transition: grid-template-columns 0.5s ease;
}

:root {
    --sidebar-width-expanded: 250px;
    --sidebar-width-collapsed: 70px;
}

.home-container {
    --sidebar-width: var(--sidebar-width-expanded);
}

.home-container-lateral-bar-hidden {
    --sidebar-width: var(--sidebar-width-collapsed);
}

/* HEADER */

.home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: header;
    max-height: 30px;
    width: 100%;
    background-color: var(--green1);
    color: white;
    padding: 20px;
    text-align: center;
    overflow: hidden;
}

.home-header-title {
    text-align: center;
    margin: 0;
}

.home-header-icon-container, .home-header-icon-group-container {
    display: flex;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
}

.home-header-icon-container:hover {
    background-color: var(--green2);
}

.home-header-profile-options-container {
    background-color: var(--green2);
    position: absolute;
    z-index: 9999;
    top: 40px;
    right: 20px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transform: translateY(-150%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Cuando se muestra */
.home-header-profile-options-container.visible {
    transform: translateY(0); /* Aparece en su posición */
    opacity: 1;
}

/* Cuando se oculta */
.home-header-profile-options-container.hidden {
    transform: translateY(-150%); /* Se mueve hacia arriba */
    opacity: 0;
}

.home-header-profile-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--green1);
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 6px;
}

.home-header-single-option-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 6px;
}

.home-header-single-option-container:last-child {
    margin-bottom: 0px;
}

.home-header-single-option-container:hover {
    background-color: var(--green1);
}

/* SIDEBAR */

.sidebar {
    grid-area: sidebar;
    background-color: var(--green1);
    color: white;
    margin-left: -20px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: width 1s ease;
}

.sidebar-table {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-table-element {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.sidebar-table-element:hover {
    background-color: var(--green2);
}

.sidebar-table-element.selected {
    background-color: var(--green2);
}

.sidebar-table-element-label {
    margin-left: 10px;
    opacity: 0;
    transform: translateX(-20px);
    white-space: nowrap;
    overflow: hidden;
}

.sidebar.expanded .sidebar-table-element-label {
    opacity: 1;
    transform: translateX(0);
}

.sidebar.collapsed .sidebar-table-element-label {
    opacity: 0;
    transform: translateX(-2000px);
}

.sidebar-icon-container {
    display: flex;
    justify-content: center;
    width: 20px;
}

@keyframes rotate-expand {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-collapse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.sidebar-icon-container.expand {
    animation: rotate-expand 0.5s ease;
}

.sidebar-icon-container.collapse {
    animation: rotate-collapse 0.5s ease;
}

/* MAIN HOME */

.main-content {
    grid-area: main-content;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-right: 10px;
}

.content-1, .content-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--green4);
}


/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .main-content {
        grid-area: main-content;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}