@import "./theme.css";

.employee-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--green3);
    border-style: solid;
    border-width: 1px;
    border-color: var(--green2);
    border-radius: 6px;
    padding: 10px;
}

.employee-item-container:hover {
    background-color: var(--green2);
}

.employee-item-label-role {
    font-style: italic;
    margin-bottom: 10px;
}

.employee-item-label-name {
    font-size: 20px;
    font-weight: bold;
}

.employee-item-label-username {
    font-size: 18px;
    margin-bottom: 10px;
}

.employee-item-label-contact-data {
    font-size: 16px;
    font-style: italic;
}

.employee-item-delete-button {
    align-self: center;
    margin-top: 10px;
    width: 50%;
    background-color: var(--red2);
    border-radius: 6px;
    border-width: 0px;
    font-size: 16px;
    color: white;
    padding: 5px;
}

.employee-item-delete-button:hover {
    background-color: var(--red4);
}

/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .employee-item-label-role {
        font-size: 12px;
    }
    .employee-item-label-name {
        font-size: 16px;
    }
    .employee-item-label-username {
        font-size: 14px;
    }
    .employee-item-label-contact-data {
        font-size: 12px;
    }
}