@import './theme.css';

/* RESPONSIVE */
@media (max-width: 768px) {
}

@media (max-width: 480px) {
    .consults-date-input {
        width: 100%;
    }
}

/* COMMON */

.consults-date-selector-container {
    display: flex;
    justify-content: space-around;
    background-color: var(--green2);
    border-radius: 6px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.consults-date-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
}

.consults-date-input {
    text-align: center;
    font-size: 16px;
    padding: 6px;
    border-radius: 6px;
    border-width: 0px;
}

.consults-days-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--green2);
    padding: 10px;
    border-radius: 6px;
    max-height: 65vh;
    overflow-y: auto;
}

.consults-service-list-container {
    display: flex;
    flex-direction: column;
    background-color: var(--green2);
    padding: 10px;
    border-radius: 6px;
    max-height: 90vh;
    overflow-y: auto;
}

.consults-options-service-day-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 30px;
    margin-bottom: 10px;
    font-size: 24px;
}

.consults-delete-service-day-button {
    font-size: 18px;
    padding: 4px;
    border-radius: 6px;
    border-width: 0px;
    background-color: var(--red7);
    color: white;
}

.consults-title-no-service-list {
    align-self: center;
}

/* ADMIN */

.consults-admin-driver-selector-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 5px;
    justify-content: space-evenly;
}

.consults-admin-label-driver {
    background-color: var(--green7);
    padding: 10px;
    font-size: 18px;
    border-radius: 6px;
}

.consults-admin-label-driver:hover {
    background-color: var(--green2);
}

.consults-admin-label-driver-selected {
    background-color: var(--green1);
    font-size: 20px;
    font-weight: bold;
    color: white;
}

/* DRIVER */