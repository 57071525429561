@import "./theme.css";

.add-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--green2);
    padding: 30px;
    border-radius: 6px;
    position: relative;
}

.add-service-form {
    display: flex;
    flex-direction: column;
}

.add-service-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
}

.add-service-input {
    padding: 10px;
    border-radius: 6px;
    border-width: 0px;
}

.add-service-checkbox-container {
    display: flex;
    align-self: center;
    align-items: center;
    gap: 10px;
}

.add-service-checkbox {
    width: 30px;
    height: 30px;
}

.add-service-buttons-container {
    display: flex;
    align-self: center;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.add-service-button-save-service {
    align-self: center;
    margin-top: 20px;
    padding: 10px;
    width: 50%;
    border-radius: 6px;
    background-color: var(--green3);
    border-width: 0cap;
}

.add-service-button-delete-service {
    align-self: center;
    margin-top: 20px;
    padding: 10px;
    width: 50%;
    border-radius: 6px;
    background-color: var(--red7);
    color: white;
    border-width: 0cap;
}

.add-service-close-edit-service-icon {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 30px;
}