@import './theme.css';

.register-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    background-color: var(--green2);
    color: white;
    gap: 40px;
}

.register-header {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    background-color: var(--green1);
    align-items: center;
    justify-content: center;
}

.register-main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
}

.register-form-selector-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 20px;
}

.register-selector-form {
    display: flex;
    background-color: var(--green7);
    padding: 1rem;
    border-radius: 6px;
    font-size: 30px;
}

.register-selector-form.selected {
    background-color: var(--green1);
    font-weight: bold;
}

.register-selector-form:hover {
    background-color: var(--green8);
}