@import './theme.css';

.total-days-container {
    display: flex;
    background-color: var(--green2);
    padding: 10px;
    justify-content: space-evenly;
    border-radius: 6px;
    margin-bottom: 10px;
}

.single-total-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--green2);
}

.label-price {
    font-size: 20px;
    font-weight: bold;
}